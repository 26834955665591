var app = {

  init: function() {
    app.sliders();

  }, // init END

  animation: function() {
    new WOW().init();
  }, // animation END

  lazyload: function() {
    var bLazy = new Blazy({
        breakpoints: [{
          width: 576, // max-width
          src: 'data-src-small'
        },
        {
          width: 990, // max-width
          src: 'data-src-medium'
        }],
        success: function(element){
          setTimeout(function(){
          // We want to remove the loader gif now.
          // First we find the parent container
          // then we remove the "loading" class which holds the loader image
          var parent = element.parentNode;
          parent.className = parent.className.replace(/\bloading\b/,'');
        }, 200);
      }
    });
  }, // lazyload END

  sliders: function() {

    var $gallerySlider01 = new Swiper('#gallerySlider01', {
      slidesPerView: 1.8,
      speed: 400,
      centeredSlides: true,
      breakpoints: {
        0: {
          slidesPerView: 1
        },
        320: {
          slidesPerView: 1
        },
        992: {
          slidesPerView: 1.2
        }
      },
    });

    var $gallerySlider02 = new Swiper('#gallerySlider02', {
      slidesPerView: 1,
      speed: 400,
      spaceBetween: 0,
      fadeEffect: {
        crossFade: true
      },
      navigation: {
        nextEl: '#manufacture-button-next',
        prevEl: '#manufacture-button-prev',
      },
      pagination: {
        el: '.swiper-pagination',
        type: 'fraction',
      },
      renderFraction: function (currentClass, totalClass) {
        return '<span class="' + currentClass + '"></span>' +
              ' of ' +
              '<span class="' + totalClass + '"></span>';
      },
      on: {
        slideNextTransitionStart: function () {
          $gallerySlider01.slideNext(400, true);
        },
        slidePrevTransitionStart: function () {
          $gallerySlider01.slidePrev(400, true);
        },
      }
    });

    var $testimonialSlider = new Swiper('#testimonialSlider', {
      // effect: 'coverflow',
      slidesPerView: 3,
      initialSlide: 1,
      speed: 400,
      spaceBetween: 0,
      centeredSlides: true,
      // coverflowEffect: {
      //   rotate: 50,
      //   stretch: 0,
      //   depth: 100,
      //   modifier: 1,
      //   slideShadows : true,
      // },
      breakpoints: {
        0: {
          slidesPerView: 1
        },
        992: {
          slidesPerView: 1
        }
      },
      navigation: {
        nextEl: '#testimonial-button-next',
        prevEl: '#testimonial-button-prev',
      },
      pagination: {
        el: '#testimonial-pagination',
        type: 'bullets',
      },
    });


    var $testimonialSlider = new Swiper('#gallerylSlider', {
      slidesPerView: 3,
      speed: 400,
      spaceBetween: 20,
      breakpoints: {
        0: {
          slidesPerView: 1
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 10,
        },
        992: {
          slidesPerView: 3
        }
      },
      navigation: {
        nextEl: '#gallery-button-next',
        prevEl: '#gallery-button-prev',
      }
    });

  }, // slider END

}

jQuery(document).ready(function($) {
  console.log( 'Документ и все ресурсы загружены' );
  app.init();
});
